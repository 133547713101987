<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_notice')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text> 
            <v-container>
                <form>
                    <v-text-field v-model="editedData.subject"
                                  dense
                                  outlined
                                  :label="$t('subject_name')"></v-text-field>
                    <v-text-field v-model="editedData.subjectNp"
                                  dense
                                  outlined
                                  :label="$t('subject_name_nepali')"></v-text-field>
                    <v-textarea v-model="editedData.text"
                                  :label="$t('notice_eng')"
                                dense
                                  outlined
                                  :error-messages="TextErrors"
                                  required
                                  @input="$v.editedData.text.$touch()"
                                  @blur="$v.editedData.text.$touch()"></v-textarea>
                    <v-textarea v-model="editedData.textNp"
                                dense
                                  outlined
                                  :label="$t('notice_nepali')"></v-textarea>
                    <v-switch v-model="editedData.isActive"
                              :label="$t('show_notice')"></v-switch>
                    <!-- <v-checkbox v-model="formData.isActive" label="Activate User"></v-checkbox> -->

                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "EditNotice",
        props: ["success", "editData"],
        computed: {
            SubjectErrors() {
                const errors = [];
                if (!this.$v.editedData.subject.$dirty) return errors;
                !this.$v.editedData.subject.required &&
                    errors.push("Subject is required.");
                return errors;
            },
            TextErrors() {
                const errors = [];
                if (!this.$v.editedData.text.$dirty) return errors;
                !this.$v.editedData.text.required &&
                    errors.push("Text is required.");
                return errors;
            }
        },
        data() {
            return {
                returnData: [],
                show: false,
                updated: false,
                editedData: {
                    subject: "",
                    text: "",
                    isActive: 0,
                    subjectNp: "",
                    textNp: "",

                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                subject: { required },
                text: { required },
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                const response = await axios.get("Report/GetNoticeListAsyncByID/" + this.editData);
                console.log(response.data)
                this.editedData = response.data
                this.updated = false;
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        isActive: this.editedData.isActive,
                        subject: this.editedData.subject,
                        subjectNp: this.editedData.subjectNp,
                        text: this.editedData.text,
                        textNp: this.editedData.textNp,
                        noticeID: this.editedData.noticeID
                    };
                    axios.post("Report/UpdateNoticeAsync", param).then(response => {
                        if (response.data.success) {
                            this.updated = true;
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Notice Updated Successfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Notice, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()

                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
            }
        }
    };
</script>

<style scoped>
</style>