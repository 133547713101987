<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_notice')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text> 
            <v-container>
                <form>
                    <v-text-field
                            v-model="editedData.Subject"
                            :label="$t('subject_name')"
                                  dense
                                  outlined
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.SubjectNp"
                                  dense
                                  outlined
                            :label="$t('subject_name_nepali')"
                    ></v-text-field>
                    <v-textarea
                            v-model="editedData.Text"
                            :label="$t('notice_eng')"
                                dense
                                  outlined
                            :error-messages="TextErrors"
                            required
                            @input="$v.editedData.Text.$touch()"
                            @blur="$v.editedData.Text.$touch()"
                    ></v-textarea>
                    <v-textarea
                            v-model="editedData.TextNp"
                            :label="$t('notice_nepali')"
                                dense
                                  outlined
                    ></v-textarea>
                    <v-switch
                            v-model="editedData.IsActive"
                            :label="$t('show_notice')"
                    ></v-switch>
                    <!-- <v-checkbox v-model="formData.isActive" label="Activate User"></v-checkbox> -->

                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "CreateNotice",
        props: ["success"],
        computed: {
            SubjectErrors() {
                const errors = [];
                if (!this.$v.editedData.Subject.$dirty) return errors;
                !this.$v.editedData.Subject.required &&
                errors.push("Subject is required.");
                return errors;
            },
            TextErrors() {
                const errors = [];
                if (!this.$v.editedData.Text.$dirty) return errors;
                !this.$v.editedData.Text.required &&
                errors.push("Text is required.");
                return errors;
            }
        },
        data() {
            return {
                returnData: [],
                show: false,
                updated: false,
                editedData: {
                    Subject: "",
                    Text: "",
                    IsActive: 0,
                    SubjectNp: "",
                    TextNp: "",

                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                Subject: {required},
                Text: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {

                this.updated = false;
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        IsActive: this.editedData.IsActive,
                        Subject: this.editedData.Subject,
                        SubjectNp: this.editedData.SubjectNp,
                        Text: this.editedData.Text,
                        TextNp: this.editedData.TextNp
                    };
                    axios.post("Report/InsertIntoNoticeAsync", param).then(response => {
                        if (response.data.success) {
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Notice added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Notice, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()

                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
            }
        }
    };
</script>

<style scoped>
</style>